import axios from 'axios'
import jwtDecode from 'jwt-decode'

const BACKEND_URL = process.env.REACT_APP_CECIL_BACKEND_API_URL ?
  process.env.REACT_APP_CECIL_BACKEND_API_URL :
  'http://localhost:4000'

console.log ("XXX: " +process.env.REACT_APP_CECIL_BACKEND_API_URL )
console.log ("BACKEND_URL: " + BACKEND_URL )

// instantiate axios
const httpClient = axios.create({
  withCredentials: true,
  responseType: 'json',
  timeout: 10000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
  }
})

httpClient.logIn = async function(credentials) {
  try {
    const serverResponse = await this({
      method: 'post',
      url: BACKEND_URL + '/api/authenticate',
      data: credentials
    });
    const token = serverResponse.data.token
    if(token) {
      // sets token as an included header for all subsequent api requests
      this.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      return true
    } else {
      return false
    }

  } catch (err) {
    console.error(err);
  }
}

httpClient.refreshToken = async function() {
	return await this({ method: 'post', url: BACKEND_URL + '/api/refresh-token', data: '' })
		.then((serverResponse) => {
			const token = serverResponse.data.token
			if(token) {
				// sets token as an included header for all subsequent api requests
        this.defaults.headers.common['Authorization'] = 'Bearer ' + token;
				return jwtDecode(token)
			} else {
				return false
			}
		})
}

httpClient.getURL = async function(URL) {
  try {
    const serverResponse = await this.get(BACKEND_URL + URL);
    return serverResponse.data
  } catch (err) {
    // Try again with refresh token!
    try {
      await this.refreshToken();
      const serverResponse = await this.get(BACKEND_URL + URL);
      return serverResponse.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
}

export default httpClient