import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from "react-router";

import Header from './Header';
import Login from './pages/Login';
import Users from './pages/Users';
import AuthLog from './pages/AuthLog';

export const SearchQuery = React.createContext({});
const HeaderWithRouter = withRouter(Header);

function App() {
  const [search, setSearch] = useState("");

  const getUserfromLocalStorage = () => {
    const user = {}
    user.email = localStorage.getItem('currentUser');
    return user;
  }

  const [currentUser, setCurrentUser] = useState(getUserfromLocalStorage);

  function PrivateRoute ({ children, ...rest }) {
    return (
      <Route {...rest} render={() => {
        return currentUser.email
          ? children
          : <Redirect to="/login" />
      }} />
    );
  }

  useEffect(() => {
    if (currentUser.email) {
      localStorage.setItem('currentUser', currentUser.email);
    } else  {
      localStorage.removeItem('currentUser');
    }
  }, [currentUser]);

  return (
    <div className="App">
      <SearchQuery.Provider value={[search,setSearch]}>
        <Router>
          <HeaderWithRouter setCurrentUser={setCurrentUser} isLoggedIn={currentUser.email ? true : false} />
          <Switch>
            <PrivateRoute path="/users" ><Users currentUser={currentUser}/></PrivateRoute>
            <PrivateRoute path="/authlog" ><AuthLog currentUser={currentUser}/></PrivateRoute>
            <Route path="/login" ><Login setCurrentUser={setCurrentUser}/></Route>
            <Route path="/"><Redirect to="/users" /></Route>
          </Switch>
        </Router>
      </SearchQuery.Provider>
    </div>
  );
}

export default App;
