import { useContext } from 'react';
import { Navbar, Nav, Form, FormControl, Button } from 'react-bootstrap';
import { SearchQuery } from './App';
import { Link } from 'react-router-dom';



const Header = ({ location, isLoggedIn, setCurrentUser }) => {
  const [search, setSearch] = useContext(SearchQuery);

  const LoggedInHeaders = (
    <>
    <Nav className="mr-auto" activeKey={location.pathname}>
      <Nav.Link as={Link} to="/users">Users</Nav.Link>
      <Nav.Link as={Link} to="/authlog">Log</Nav.Link>
    </Nav>
    <Form inline>
      <FormControl value={search} onChange={(e) => setSearch(e.target.value)} type="text" placeholder="Search" className="mr-sm-2" autoFocus/>
    </Form>
    <Button variant="outline-light" onClick={() => setCurrentUser('')}>Log Out</Button>
    </>
  )

  return (
    <Navbar bg="primary" variant="dark" className="sticky-top">
      <Navbar.Brand href="/users">WiFi RADIUS Server</Navbar.Brand>
      {isLoggedIn && LoggedInHeaders}
    </Navbar>
  );
};

export default Header;