import React from 'react';
import {useState, useEffect, useContext} from 'react';
import { Table, Card } from 'react-bootstrap';
import {SearchQuery} from '../App';
import {dynamicSort} from '../Common';
import httpClient from '..//httpClient'
import {useHistory} from 'react-router-dom';

const Users = ({currentUser}) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search] = useContext(SearchQuery);
  let history = useHistory();

  const fetchUsers = async () => {
    const result = await httpClient.getURL('/api/users');
    if (result) {
      result.sort(dynamicSort("BillingName"));
      setUsers(result);
    } else {
      history.push('/login');
    }
  }

  useEffect(() => {
    fetchUsers();
    const timer = setInterval(fetchUsers, 10000);
    return () => {
      console.log('Users will Unmount');
      clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (search) {
      const updated_filtered_users = users.filter(user => {return (
        user.FullName.toUpperCase().includes(search.toUpperCase()) ||
        user.Email.toUpperCase().includes(search.toUpperCase()) ||
        user.TeamName.toUpperCase().includes(search.toUpperCase()) ||
        user.BillingName.toUpperCase().includes(search.toUpperCase()) ||
        user.VLAN.toUpperCase().includes(search.toUpperCase())
      )});
      setFilteredUsers(updated_filtered_users);
    } else {
      setFilteredUsers(users);
    }
  }, [search, users])


  return (
    <div>
      <Card style={{ margin: '10px 10px' }}>
        <Table  striped bordered hover size="sm" style={{ margin: '0px' }}>
          <thead className="thead-light">
            <tr>
              <th>Username</th>
              <th>Full Name</th>
              <th>Company</th>
              <th>Team</th>
              <th>Password</th>
              <th>VLAN</th>
              <th>Active</th>
              <th>UserActive</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => {
              return (
                <tr key={index}>
                  <td>{user.Email}</td>
                  <td>{user.FullName}</td>
                  <td>{user.BillingName}</td>
                  <td>{user.TeamName}</td>
                  <td>{user.AccessPincode}</td>
                  <td>{user.VLAN}</td>
                  <td>{user.Active}</td>
                  <td>{user.UserActive}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Card>
    </div>
  );
}

export default Users;
