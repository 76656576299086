import './Login.css';
import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import httpClient from '../httpClient';

const Login = (props) => {
  let history = useHistory();

  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const handleChange = e => {
    const newFormData = {...formData, [e.target.name]: e.target.value};
    setFormData(newFormData);
  };

  const  handleSubmit = async (evt) => {
    evt.preventDefault()

    if (await httpClient.logIn(formData)) {
      props.setCurrentUser({email: formData.email});
      history.push('/users');
    } else {
      console.log("Login Failed")
    }
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <form onSubmit={handleSubmit}>
          <h3>Sign In</h3>
          <div className="form-group">
            <label>Username</label>
            <input type="text" name="email" className="form-control" placeholder="Enter username"
              value={formData.email} onChange={handleChange} required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input type="password" name="password" className="form-control" placeholder="Enter password"
              value={formData.password} onChange={handleChange} required
            />
          </div>
          <br />
          <button type="submit" className="btn btn-primary btn-block" >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
