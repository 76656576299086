import React from 'react';
import {useState, useEffect, useContext} from 'react';
import { Table, Card } from 'react-bootstrap';
import {SearchQuery} from '../App';
import httpClient from '../httpClient'
import {useHistory} from 'react-router-dom';

const AuthLog = ({currentUser}) => {
  const [authlogRow, setAuthlogRow] = useState(0);
  const [authlog, setAuthlog] = useState([]);
  const [filteredAuthlog, setFilteredAuthlog] = useState([]);
  const [search] = useContext(SearchQuery);
  let history = useHistory();

  const fetchAuthlog = async () => {
    const result = await httpClient.getURL('/api/authlog');
    if (result) {
      result.data.forEach(function(part, index) {
        this[index].datetime = new Date(part.datetime).toLocaleString('sv');
      }, result.data);
      setAuthlog(result.data);
    } else {
      history.push('/login');
    }
  }

  const checkNewAuthlogRows = async () => {
    const result = await httpClient.getURL('/api/authlog-rows');
    if (result.rows > authlogRow) {
      setAuthlogRow(result.rows);
    }
  }

  useEffect(() => {
    checkNewAuthlogRows();
    const timer = setInterval(checkNewAuthlogRows, 5000);
    return () => {
      console.log('Authlog will Unmount');
      clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchAuthlog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authlogRow])

  useEffect(() => {
    if (search) {
      const updated_filtered_authlog = authlog.filter(log => {return (
        log.datetime.toUpperCase().includes(search.toUpperCase()) ||
        log.username.toUpperCase().includes(search.toUpperCase()) ||
        log.message.toUpperCase().includes(search.toUpperCase())
      )});
      setFilteredAuthlog(updated_filtered_authlog);
    } else {
      setFilteredAuthlog(authlog);
    }
  }, [search, authlog])

  return (
    <div>
      <Card style={{ margin: '10px 10px' }}>
        <Table  striped bordered hover size="sm" style={{ margin: '0px' }}>
          <thead className="thead-light">
            <tr>
              <th style={{ width: '250px' }}>Date/Time</th>
              <th>Username</th>
              <th className="text-left">Message</th>
            </tr>
          </thead>
          <tbody>
            {filteredAuthlog.map((log, index) => {
              return (
                <tr key={index}>
                  <td>{log.datetime}</td>
                  <td>{log.username}</td>
                  <td className="text-left">{log.message}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Card>
    </div>
  );
}

export default AuthLog;